
export default {
  name: 'tablo-filter',
  model: {
    prop: 'request',
    event: 'change',
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        this.$forceNextTick(() => {
          if (value) this.$refs.search.$refs.rzSearch.term = this.request.term;
        });
      },
    },
  },
};
