
export default {
  name: 'tablo-controls',
  model: {
    prop: 'request',
    event: 'change',
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFilter: false,
    };
  },
  methods: {
    openFilter() {
      this.isFilter = true;
    },
    closeFilter() {
      this.isFilter = false;
    },
    search(e) {
      this.$emit('search', e);
    },
  },
};
