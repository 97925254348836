
import {mapGetters} from 'vuex';
import TabloRequest from '~/requests/tablo';
import TabloList from '~/entities/tablo-list';
import {debounce} from '~/utils';
import createSender from '~/api/sender';

export default {
  name: 'section-tablo',
  props: {
    tablo: {
      type: Object,
      default: () => ({}),
    },
    compact: {
      type: Boolean,
      default: false,
    },
    whiteFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sender: null,
      request: new TabloRequest(this.$route.name === 'tablo'),
      tabloList: {},
      tabloLoading: false,
      search: debounce(this.loadTablo, 400),
    };
  },
  computed: {
    ...mapGetters('sulu/airport', ['about']),
  },
  watch: {
    'request.direction': {
      handler() {
        this.loadTablo();
      },
      deep:true,
    },
    'request.date': {
      handler(val, oldVal) {
        if (oldVal && val && (oldVal.date !== val.date || oldVal.month.value !== val.month.value || oldVal.year !== val.year)) {
          this.request.time = this.request.timeOptions[this.request.timeOptions.length - 1];
          this.search();
        }
      },
      deep: true,
    },
  },
  created () {
    this.sender = createSender(this.$store.state.sulu.host);
    
    if (Object.keys(this.tablo).length) {
      this.transformTablo(this.tablo);
    } else {
      this.loadTablo();
    }
  },
  mounted () {
    if (this.$route.name === 'tablo' && this.$route.query) {
      this.request.fillFilter(this.$route.query);
      if (!this.isMobile && this.$route.query.term) 
        this.$refs.tabloControls.$refs.tabloFilter.$refs.search.$refs.rzSearch.term = this.$route.query.term;
    }
  },
  methods: {
    transformTablo (data) {
      this.tabloList = new TabloList(data, this.request.timeOptions, this.request.direction.altValue);
    },
    loadTablo(term = '') {
      if (term && term.length) this.request.time = this.request.timeOptions[this.request.timeOptions.length - 1];
      
      this.request.term = term;
      this.tabloLoading = true;

      const params = this.request.buildRequest(this.about.code);
      const {timeOptions, direction} = this.request;

      this.sender
        .get('/asb/flights', { params })
        .then(res => {
          this.tabloList = new TabloList(res.data, timeOptions, direction.altValue);
          this.$forceNextTick(() => this.$nuxt.$emit('tablo-loaded'));
        })
        .finally(() => {
          this.tabloLoading = false;
        });
    },
    nextDay() {
      this.request.nextDay();
      window.scrollTo({top: 0});
    },
  },
};
