
export default {
  name: 'news-list',
  props: {
    list: {
      type:Array,
      default: () => ([]),
    },
    firstBig: {
      type: Boolean, 
      default: false,
    },
  },
  computed: {
    slicedNews() {
      return this.list.slice(0, 5);
    },
  },
};
