import axios from 'axios';
import Vue from 'vue';

export default function (host) {
  const Sender = axios.create({
    baseURL: `${host}/inside`,
    timeout: 25000,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': Vue.prototype.$locale || 'ru',
    },
  });
  
  Sender.interceptors.request.use((async (request) => {
    return {
      ...request,
      headers: {
        ...request.headers,
        'Accept-Language': Vue.prototype.$locale || 'ru',
        'mobile': Vue.prototype.$mobile || 'false',
      },
    };
  }));

  return Sender;
}

// export default Sender;