import TabloRequest from '~/requests/tablo';
import createSender from '~/api/sender';

export async function getTabloData ({ store, route }) {
  const req = new TabloRequest(route.name === 'tablo');
      
  if (route.name === 'tablo' && route.query) {
    req.fillFilter(route.query);
  }

  const {host} = store.state.sulu;
  const about = store.getters['sulu/airport/about'];
  const sender = createSender(host);
  const reqParams = req.buildRequest(about.code);
  const {data} = await sender
    .get('/asb/flights', { params: reqParams })
    .catch(() => {
      return Promise.resolve({ data: {} });
    });

  return data;
}