import { render, staticRenderFns } from "./autocomplete.vue?vue&type=template&id=09d2e862&"
import script from "./autocomplete.vue?vue&type=script&lang=js&"
export * from "./autocomplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzIconCross: require('/opt/airport.kg/node_modules/razlet-ui/src/icons/icon-cross/index.vue').default,RzIconDropArrow: require('/opt/airport.kg/node_modules/razlet-ui/src/icons/icon-drop-arrow/index.vue').default,RzDropdown: require('/opt/airport.kg/node_modules/razlet-ui/src/components/dropdown/index.vue').default})
