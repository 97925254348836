
export default {
  name: 'section-offers',
  components: {
    'owl-carousel': () => process.client ? import('vue-owl-carousel') : null,
  },
  data () {
    return {
      selected: 0,
    };
  },
  computed: {
    offers () {
      return [
        {
          bg: '#003E92',
          title: `${this.$t('index.banner.title[0]')}<br>${this.$t('index.banner.title[1]') }`,
          subtitle: this.$t('index.banner.subtitle'),
          image: 'banner_bag_big',
        },
        {
          bg: '#1BA1ED',
          title: `${this.$t('index.banner.title[0]')}<br>${this.$t('index.banner.title[1]') }`,
          subtitle: this.$t('index.banner.subtitle'),
          image: 'banner_bag_big',
        },
        {
          bg: '#EDA41A',
          title: `${this.$t('index.banner.title[0]')}<br>${this.$t('index.banner.title[1]') }`,
          subtitle: this.$t('index.banner.subtitle'),
          image: 'banner_bag_big',
        },
      ];
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.carousel.showPrev = true;
      this.$refs.carousel.showNext = true;

      this.getPrev().classList.add('section-offers__prev');
      this.getNext().classList.add('section-offers__next');
    }, 200);
  },
  methods: {
    getPrev() {
      return document.getElementById(this.$refs.carousel.prevHandler);
    },
    getNext() {
      return document.getElementById(this.$refs.carousel.nextHandler);
    },
    getDots() {
      return document.getElementsByClassName('owl-dot');
    },
    prevOffer() {
      if (this.selected !== 0) this.selected -= 1;
      else this.selected = 2;
      this.getPrev().click();
    },
    nextOffer() {
      if (this.selected < 2) this.selected += 1;
      else this.selected = 0;
      this.getNext().click();
    },
    selectOffer(index) {
      this.selected = index;
      this.getDots()[index].click();
    },
  },
};
