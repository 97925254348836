
export default {
  name: 'base-tab-button',
  model: {
    prop: 'item',
    event: 'change',
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(option, value) {
      if (typeof value !== 'undefined') this.$emit('change', option);
    },
  },
};
