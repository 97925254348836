import { render, staticRenderFns } from "./list.vue?vue&type=template&id=7b5f715b&"
import script from "./list.vue?vue&type=script&lang=js&"
export * from "./list.vue?vue&type=script&lang=js&"
import style0 from "./list.vue?vue&type=style&index=0&id=7b5f715b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsCard: require('/opt/airport.kg/src/components/news/card.vue').default,RzButton: require('/opt/airport.kg/node_modules/razlet-ui/src/components/button/index.js').default})
