
import {unlockScroll} from '~/utils/lock-scroll';

export default {
  name: 'base-header',
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDrawer: false,
      activeMenuIndex: null,
      menu: [],
    };
  },
  watch: {
    '$route': {
      handler() {
        this.closeDrawer();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nuxt.$on('route-changed', unlockScroll());
  },
  beforeDestroy() {
    this.$nuxt.$off('route-changed');
  },
  methods: {
    openDrawer() {
      this.$refs.header.style = 'z-index: 1600;';
      this.isDrawer = true;
    },
    closeDrawer() {
      if (this.isDrawer) {
        this.isDrawer = false;
        setTimeout(() => {
          this.$refs.header.style = this.isMobile ? 'z-index: 99;' : 'z-index: 110;';
        }, 400);
      }
    },
    focusSearch () {
      this.$refs.search.$refs.autocomplete.$refs.input.focus();
    },
  },
};
