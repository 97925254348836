
export default {
  name: 'service-main-list',
  data() {
    return {
      services: [
        {
          name: 'tickets',
          icon: 'icon-ticket',
          image: 'ticket_hover.webp',
          color: '#FFFFFF',
          colorHover: '#FFFFFF',
          bg: '#E82A2A',
          bgHover: '#1E4D7E',
          href: '/sale',
          fill: '#FFFFFF',
        },
        {
          name: 'lounge',
          icon: 'icon-lounge',
          image: 'lounge_hover.webp',
          color: '#222222',
          colorHover: '#FFFFFF',
          bg: '#FFFFFF',
          bgHover: '#404040',
          href: '/services/business-hall',
          fill: '#003E92',
        },
        {
          name: 'transfer',
          icon: 'icon-transfer',
          image: 'transfer_hover.webp',
          color: '#222222',
          colorHover: '#FFFFFF',
          bg: '#FFFFFF',
          bgHover: '#1BA1ED',
          href: '/services/taxi',
          fill: '#003E92',
        },
        {
          name: 'parking',
          icon: 'icon-parking',
          image: 'parking_hover.webp',
          color: '#222222',
          colorHover: '#FFFFFF',
          bg: '#FFFFFF',
          bgHover: '#EDA41A',
          href: '/services/parking',
          fill: '#003E92',
        },
      ],
    };
  },
};
