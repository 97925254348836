
export default {
  name: 'tablo-status-badge',
  props: {
    status: {
      type: Object,
      required: true,
    },
  },
};
