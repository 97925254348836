
export default {
  name: 'tablo-status-icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  watch: {
    icon: {
      handler(icon) {
        // eslint-disable-next-line prefer-template, no-useless-concat
        this.$options.components[icon] = () => import('../icon/statuses/' + icon + '.vue');
      },
      immediate: true,
    },
  },
/*   created() {
    // eslint-disable-next-line prefer-template, no-useless-concat
    this.$options.components[this.icon] = () => import('../../icons/statuses/' + this.icon + '.vue');
  }, */
};
