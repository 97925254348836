
export default {
  name: 'tablo-row',
  props: {
    item: {
      type: Object,
      required: true,
    },
    direction: {
      type: String,
      required: true,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    oppositeDirection() {
      return this.direction === 'from' ? 'to' : 'from';
    },
  },
};
