
export default {
  name: 'base-logo',
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    withoutText: {
      type: Boolean,
      default: false,
    },
  },
};
