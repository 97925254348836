
import { getSuluPage, fillSeo, getSuluApi, handleResponse} from 'razlet-sulu-sdk/lib/utils/helpers';
import { getTabloData } from '~/utils/tablo';
import Sulu from 'razlet-sulu-sdk/lib/entities/factory';

export default {
  name: 'index-page',
  layout: 'index',
  async asyncData ({ store, route, error, redirect}) {
    const results = await Promise.allSettled([
      getSuluPage({ store, route, error, redirect }),
      getSuluApi({ store, error, redirect, path: `news-list` }),
    ]);
    const [result, news] = handleResponse(results, error);
    const tablo = await getTabloData({ store, route });

    return {result, news, tablo};
  },
  data() {
    return {
      news: {},
      result: {},
      tablo: {},
      isAffiliatorLoaded: false,
      isMounted: false,
      loading: true,
    };
  },
  created() {
    this.fillSulu(this.result, this.news);
  },
  mounted() {
    this.$bus.$on('locale-changed', () => {
      this.loading = true;
      window.scrollTo(0, 0);
      this.$nuxt.refresh().then(() => {
        this.fillSulu(this.result, this.news);
      });
    });
    this.$bus.$on('load-aff', () => {
      this.isMounted = true;
    });
  },
  destroyed() {
    this.$bus.$off('locale-changed');
    this.$bus.$off('load-aff');
  },
  methods: {
    fillSulu(res, news) {
      const suluFactory = new Sulu();
      this.result = suluFactory.create(res);
      this.news = suluFactory.create(news);
      this.loading = false;
    },
  },
  head() {
    return {
      ...fillSeo(this, this.result.seo),
      script: [
        { 
          skip: !this.isMounted,
          hid: 'avia',
          vmid: 'avia', 
          type: 'text/javascript',
          // type: 'text/partytown',
          src: `${process.env.affURL}/manas/aviaHome/main.js`,
          async: true,
          callback: () => { 
            this.isAffiliatorLoaded = true;
            this.$bus.$emit('show-aff');
          },
        },
      ],
    };
  },
};
