import { render, staticRenderFns } from "./select.vue?vue&type=template&id=3997d46b&"
import script from "./select.vue?vue&type=script&lang=js&"
export * from "./select.vue?vue&type=script&lang=js&"
import style0 from "./select.vue?vue&type=style&index=0&id=3997d46b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzRadioGroup: require('/opt/airport.kg/node_modules/razlet-ui/src/components/radio-group/index.vue').default,RzButton: require('/opt/airport.kg/node_modules/razlet-ui/src/components/button/index.js').default,RzBottomBar: require('/opt/airport.kg/node_modules/razlet-ui/src/components/bottom-bar/index.vue').default})
