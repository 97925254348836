import { render, staticRenderFns } from "./date.vue?vue&type=template&id=733029b0&"
import script from "./date.vue?vue&type=script&lang=js&"
export * from "./date.vue?vue&type=script&lang=js&"
import style0 from "./date.vue?vue&type=style&index=0&id=733029b0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconCalendar: require('/opt/airport.kg/src/components/icon/calendar.vue').default,RzDateCalendar: require('/opt/airport.kg/node_modules/razlet-ui/src/components/date-calendar/index.vue').default,RzButton: require('/opt/airport.kg/node_modules/razlet-ui/src/components/button/index.js').default,RzBottomBar: require('/opt/airport.kg/node_modules/razlet-ui/src/components/bottom-bar/index.vue').default})
