import { render, staticRenderFns } from "./table.vue?vue&type=template&id=c853a2f2&"
import script from "./table.vue?vue&type=script&lang=js&"
export * from "./table.vue?vue&type=script&lang=js&"
import style0 from "./table.vue?vue&type=style&index=0&id=c853a2f2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TabloCard: require('/opt/airport.kg/src/components/tablo/card.vue').default,TabloRow: require('/opt/airport.kg/src/components/tablo/row.vue').default,RzSimpleTable: require('/opt/airport.kg/node_modules/razlet-ui/src/components/simple-table/index.vue').default,IconShare: require('/opt/airport.kg/src/components/icon/share.vue').default,RzIconCross: require('/opt/airport.kg/node_modules/razlet-ui/src/icons/icon-cross/index.vue').default,RzSidebar: require('/opt/airport.kg/node_modules/razlet-ui/src/components/sidebar/index.vue').default})
