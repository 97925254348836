
export default {
  name: 'section-services',
  data () {
    return {
      list: [
        {
          name: 'cafe',
          icon: 'dutyfree',
          link: '/services/duty-free',
          image: 'dutyfree',
        },
        {
          name: 'lounge',
          icon: 'lounge',
          link: '/services/business-hall',
          image: 'lounge',
        },
        {
          name: 'parking',
          icon: 'parking',
          link: '/services/parking',
          image: 'parking',
        },
        {
          name: 'transfer',
          icon: 'transfer',
          link: '/services/taxi',
          image: 'transfer',
        },
      ],
    };
  },
};
