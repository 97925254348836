import { render, staticRenderFns } from "./tablo.vue?vue&type=template&id=1bc05fd8&"
import script from "./tablo.vue?vue&type=script&lang=js&"
export * from "./tablo.vue?vue&type=script&lang=js&"
import style0 from "./tablo.vue?vue&type=style&index=0&id=1bc05fd8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseOrnamentBg: require('/opt/airport.kg/src/components/base/ornament-bg.vue').default,IconArrowLong: require('/opt/airport.kg/src/components/icon/arrow-long.vue').default,TabloControls: require('/opt/airport.kg/src/components/tablo/controls.vue').default,RzPreloader: require('/opt/airport.kg/node_modules/razlet-ui/src/components/preloader/index.vue').default,TabloTable: require('/opt/airport.kg/src/components/tablo/table.vue').default,RzButton: require('/opt/airport.kg/node_modules/razlet-ui/src/components/button/index.js').default,RzContainer: require('/opt/airport.kg/node_modules/razlet-ui/src/components/container/index.vue').default})
