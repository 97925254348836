import { render, staticRenderFns } from "./index.vue?vue&type=template&id=386f4cf2&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=386f4cf2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSearchWithButton: require('/opt/airport.kg/src/components/base/search-with-button.vue').default,TabloFilterMobileDate: require('/opt/airport.kg/src/components/tablo/filter/mobile/date.vue').default,IconTime: require('/opt/airport.kg/src/components/icon/time.vue').default,TabloFilterMobileSelect: require('/opt/airport.kg/src/components/tablo/filter/mobile/select.vue').default,RzBottomBar: require('/opt/airport.kg/node_modules/razlet-ui/src/components/bottom-bar/index.vue').default})
