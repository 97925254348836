
import {mapGetters} from 'vuex';

export default {
  name: 'base-header-info',
  data() {
    return {
      timer: null,
      currentTime: null,
    };
  },
  computed: {
    ...mapGetters('sulu/airport', ['weather']),
  },
  created() {
    this.getDate();
    this.timer = setInterval(() => {
      this.getDate();
    }, 61000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getDate() {
      const date = new Date();
      this.currentTime = date.toLocaleString('ru-RU', { 
        timeZone: 'Asia/Bishkek',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
};
