
import {mapActions} from 'vuex';
import {onDocumentLoad} from '~/utils';

export default {
  name: 'tablo-table',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    request: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isDetails: false,
      selectedItem: null,
      docLoaded: false,
    };
  },
  mounted() {
    onDocumentLoad(() => {
      this.docLoaded = true;
      window.removeEventListener('load', () => {});
    });
    if (this.$route.query.opened) this.showDetails(this.list[0]);
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') this.hideDetails();
    });
  },
  beforeDestroy() {
    document.removeEventListener('keydown', () => {});
  },
  methods: {
    ...mapActions(['getTabloWeather']),
    showDetails(item) {
      this.selectedItem = item;
      this.isDetails = true;
      if (!item.from.weather && item.from.realDate) this.getTabloWeather({city: item.from.city, date: item.from.realDate}).then(res => {
        item.from.addWeather(res);
        this.$refs.details.fromMountKey += 1;
      });
      if (!item.to.weather && item.to.realDate) this.getTabloWeather({city: item.to.city, date: item.to.realDate}).then(res => {
        item.to.addWeather(res);
        this.$refs.details.toMountKey += 1;
      });
    },
    hideDetails() {
      this.isDetails = false;
      this.selectedItem = null;
      if (this.$route.query && this.$route.query.opened) this.$router.replace({
        name: this.$route.name,
        query: {},
      });
    },
    shareItem() {
      const link = this.generateLink(this.selectedItem);
      if (navigator.canShare) {
        navigator
          .share({
            title: `${this.$t('tablo.shared')} ${this.selectedItem.uniqueString} | ${this.$t('title')}`,
            url: link,
          })
          .then(() => {
            // eslint-disable-next-line no-console
            console.log('shared successfully');
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.error(e);
            this.copyLink(link);
          }
          );
      } else {
        this.copyLink(link);
      }
    },
    generateLink(item) {
      const query = `date=${item.plannedDate.split(' ')[0]}&direction=${this.request.direction.value}&term=${item.uniqueString}&opened=true`;
      return `https://${window.location.host}/tablo?${query}`;
    },
    async copyLink(link) {
      try {
        navigator.clipboard.writeText(link).then(() => {
          this.$bus.$emit(
            'add-removable-notification', 
            {
              theme: 'success',
              seconds: 4,
              text: this.$t('base.copySuccess'),
            }
          );
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.$bus.$emit(
          'add-removable-notification', 
          {
            theme: 'error',
            seconds: 4,
            text: this.$t('base.copyError'),
          }
        );
      }
    },
  },
};
