
export default {
  name: 'tablo-filter-mobile-select',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Object, String, Number],
      default: null,
    },
  },
  data: () => ({
    isShowList: false,
    selected: null,
  }),
  watch: {
    value: {
      handler(newVal) {
        this.selected = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    showList() {
      this.isShowList = true;
    },
    hideList() {
      this.isShowList = false;
    },
    selectItem(item) {
      this.selected = this.options.find(opt => opt.name === item);
    },
    apply() {
      this.$emit('input', this.selected);
      this.hideList();
    },
  },
};
