import { render, staticRenderFns } from "./info.vue?vue&type=template&id=6136ba4d&scoped=true&"
import script from "./info.vue?vue&type=script&lang=js&"
export * from "./info.vue?vue&type=script&lang=js&"
import style0 from "./info.vue?vue&type=style&index=0&id=6136ba4d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6136ba4d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconTime: require('/opt/airport.kg/src/components/icon/time.vue').default,IconWeather: require('/opt/airport.kg/src/components/icon/weather.vue').default,RzDottedLoader: require('/opt/airport.kg/node_modules/razlet-ui/src/components/dotted-loader/index.vue').default})
