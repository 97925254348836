import {transformDateToObject} from 'razlet-sdk/lib/utils/date';
import {isTimeInRange} from '~/utils/index';

export default class TabloItemPoint {
  #item = null;

  #dictionary = null;

  #weather = null;


  constructor(item, dictionary) {
    this.#item = item;
    this.#dictionary = dictionary;
  }

  get city() {
    return this.#item.city;
  }

  get cityName() {
    return this.#dictionary.cities[this.city] ? this.#dictionary.cities[this.city].name : '';
  }

  get airport() {
    return this.#item.airport;
  }

  get airportName() {
    return this.#dictionary.airports[this.airport] ? this.#dictionary.airports[this.airport].name : '';
  }

  get plannedDate() {
    return this.#item.plannedTime;
  }

  get realDate() {
    return this.#item.realTime;
  }

  get plannedTime() {
    return transformDateToObject(this.plannedDate).time;
  }

  get realTime() {
    return transformDateToObject(this.realDate).time;
  }

  get isTimeChanged() {
    return this.realTime !== this.plannedTime;
  }

  get weatherCode() {
    return this.#weather?.icon ? this.#weather.icon.replace(/[^\d]/g, '') : null;
  }

  get weatherIcon() {
    switch (this.weatherCode) {
    case '01':
    case '02': 
      return 'sun';
    case '09':
    case '10':
      return 'rain';
    case '11':
      return 'thunder';
    case '13':
      return 'snow';
    case '03':
    case '04':
    case '50':
      return 'clouds';
    default:
      return '';
    }
  }

  get weatherTemp() {
    const temp = this.#weather?.temp ? (this.#weather.temp).toFixed(0) : null;
    if (!temp) return '';
    return temp > 0 
      ? `+${temp}` 
      : temp ;
  }

  checkTime(range) {
    return isTimeInRange(this.plannedTime, range) || isTimeInRange(this.realTime, range);
  }

  addWeather(weather) {
    this.#weather = weather;
  }
}