
export default {
  name: 'tablo-filter-mobile-date',
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
  },
  data: () => ({
    isShowList: false,
    selected: null,
    mountKey: 0,
  }),
  computed: {
    dateString() {
      if (!this.selected) return '';
      return `${this.selected.date}.${this.selected.month.value}.${this.selected.year}`;
    }, 
  },
  watch: {
    value: {
      handler(newVal) {
        this.selected = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    showList() {
      this.isShowList = true;
    },
    hideList() {
      this.isShowList = false;
    },
    selectItem(item) {
      this.$set(this, 'selected', item);
    },
    apply() {
      this.$emit('input', this.selected);
      this.mountKey += 1;
      this.hideList();
    },
  },
};
