
export default {
  name: 'section-news',
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      analytics: [
        {
          icon: 'icon-analytics-airport',
          title: this.$t('analytics[0].title'),
          subtitle: this.$t('analytics[0].subtitle'),
        },
        {
          icon: 'icon-analytics-airplane',
          title: this.$t('analytics[1].title'),
          subtitle: this.$t('analytics[1].subtitle'),
        },
        {
          icon: 'icon-analytics-passenger',
          title: this.$t('analytics[2].title'),
          subtitle: this.$t('analytics[2].subtitle'),
        },
      ],
    };
  },
};
