
export default {
  name: 'tablo-filter',
  model: {
    prop: 'request',
    event: 'change',
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      timeClass: '',
      dateClass: '',
    };
  },
  methods: {
    switchCalendarDropdown (value) {
      this.dateClass = value ? 'tablo-filter__date--opened' : '';
    },
    switchTimeDropdown (value) {
      this.timeClass = value ? 'tablo-filter__time--opened' : '';
    },
  },
};
