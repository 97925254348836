import { render, staticRenderFns } from "./card.vue?vue&type=template&id=f75422a2&"
import script from "./card.vue?vue&type=script&lang=js&"
export * from "./card.vue?vue&type=script&lang=js&"
import style0 from "./card.vue?vue&type=style&index=0&id=f75422a2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TabloStatusBadge: require('/opt/airport.kg/src/components/tablo/status-badge.vue').default,RzAirlineIcon: require('/opt/airport.kg/node_modules/razlet-ui/src/components/airline-icon/index.vue').default})
