import { render, staticRenderFns } from "./controls.vue?vue&type=template&id=ee417af8&"
import script from "./controls.vue?vue&type=script&lang=js&"
export * from "./controls.vue?vue&type=script&lang=js&"
import style0 from "./controls.vue?vue&type=style&index=0&id=ee417af8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconFilter: require('/opt/airport.kg/src/components/icon/filter.vue').default,RzButton: require('/opt/airport.kg/node_modules/razlet-ui/src/components/button/index.js').default,BaseTabButton: require('/opt/airport.kg/src/components/base/tab-button.vue').default,TabloFilterMobile: require('/opt/airport.kg/src/components/tablo/filter/mobile/index.vue').default,TabloFilter: require('/opt/airport.kg/src/components/tablo/filter/index.vue').default})
