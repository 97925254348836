import { render, staticRenderFns } from "./status-badge.vue?vue&type=template&id=527e57f4&"
import script from "./status-badge.vue?vue&type=script&lang=js&"
export * from "./status-badge.vue?vue&type=script&lang=js&"
import style0 from "./status-badge.vue?vue&type=style&index=0&id=527e57f4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TabloStatusIcon: require('/opt/airport.kg/src/components/tablo/status-icon.vue').default})
