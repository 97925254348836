
import {mapGetters} from 'vuex';

export default {
  name: 'base-header-airport-select',
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters('sulu/airport', ['about', 'sitesList']),
    domainList() {
      return this.sitesList ? this.sitesList.map(item => Object.assign(item, {label: item.name})) : [];
    },
    city() {
      return {
        code: this.about?.code,
        label: this.about?.title,
      };
    },
  },
  methods: {
    selectCity(data) {
      if (this.about?.title !== data.label && typeof window !== 'undefined') {
        window.location.href = data.url;
      }
    },
  },
};
