
export default {
  name: 'base-search-with-button',
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    buttonName: {
      type: String,
      required: true,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
};
